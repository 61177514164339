import React from "react";
import styled from "styled-components"
import { Helmet, HelmetProvider } from "react-helmet-async";

const NosTarifs = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Anniversaire - Space Bowling</title>
        <link rel="canonical" href={`${"http://spacebowling.fr"}${window.location.pathname}`} />
      </Helmet>
      <Container className="container-fluid">
        <Body>
          <div className="title">
            <p className="title-part1">Espace</p>
            <h1 className="title-part2">BILLARD</h1>
          </div>
          <div className="contents">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4 col-center"><p>1 heure</p></div>
              <div className="col-4 col-center"><p>1/4 heure</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Lundi au Jeudi</p></div>
              <div className="col-4 col-center"><p>12€</p></div>
              <div className="col-4 col-center"><p>3€</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Vendredi au Dimanche</p></div>
              <div className="col-4 col-center"><p>14€</p></div>
              <div className="col-4 col-center"><p>3.5€</p></div>
              <hr />
            </div>
          </div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="title">
            <p className="title-part1">Espace</p>
            <h2 className="title-part2">Bowling</h2>
          </div>
          <div className="contents">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4 col-center"><p>13h/17h</p></div>
              <div className="col-4 col-center"><p>17h/fermeture</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Lundi au Jeudi</p></div>
              <div className="col-4 col-center"><p>6€</p></div>
              <div className="col-4 col-center"><p>7€</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Vendredi</p></div>
              <div className="col-4 col-center"><p>6€</p></div>
              <div className="col-4 col-center"><p>8€</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Samedi</p></div>
              <div className="col-4 col-center"><p>7€</p></div>
              <div className="col-4 col-center"><p>9€</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Dimanche</p></div>
              <div className="col-4 col-center"><p>8€</p></div>
              <div className="col-4 col-center"><p>8€</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Veilles & jrs fériés</p></div>
              <div className="col-4 col-center"><p>8€</p></div>
              <div className="col-4 col-center"><p>9€</p></div>
              <hr />
            </div>
            <div className="row row-red">
              <div className="col-sm-4 col-4"><p>Étudiants*</p></div>
              <div className="col-sm-4 col-4"><p className="twoline">5€ (CHAUSSURES COMPRISES)</p></div>
              <div className="col-sm-4 col-4"><p className="twoline">SAUF SAMEDI, DIMANCHE JUSQU’À 20H,<br /> VEILLES ET JOURS FÉRIÉS</p></div>
              <hr />
            </div>
            <div className="row row-yellow">
              <div className="col-sm-3 col-4"><p>Dimanche 19h au Mercredi</p></div>
              <div className="col-sm-9 col-8">
                <p className="twoline ">2 PARTIES ACHETÉES = 1 OFFERTE (LE MÊME JOUR)<br /></p>
                <span>VALABLE HORS VACANCES SCOLAIRES ET JOURS FÉRIÉS</span>
              </div>
              <hr />
            </div>
          </div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>

          <div className="details">
            <p>La partie par personne</p>
            <p>* SUR PRÉSENTATION <br />
              DE LA CARTE ÉTUDIANTE VALIDE DE L’ANNÉE EN COURS</p>
            <p className="p-blue">LOCATION CHAUSSURES : 2€ Chaussette (vente) 1€</p>
            <p>Port de chaussures de bowling et chaussettes obligatoires<br />
              TOUTE PARTIE COMMENCÉE EST DUE</p>
            <p>HORS PROMOTIONS EN COURS</p>
            <p className="p-white">L’ÉTABLISSEMENT SE RÉSERVE LE DROIT DE MODIFIER LES HORAIRES<br />
              DE FERMETURE À TOUT MOMENT EN FONCTION DE L’AFFLUENCE / DERNIÈRE<br />
              ATTRIBUTION DE<br />
              TABLE BILLARD OU PISTE À 1h le vendredi et le samedi</p>
            <p className="p-white p-white-petit">Nous n’acceptons ni les chèques, ni les billets de 200€ et 500€.<br />
              CB À PARTIR DE 10€. CHÈQUES VACANCES ACCEPTÉS.</p>
          </div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>

          <div className="title">
            <p className="title-part1">Laser Game</p>
            <h2 className="title-part2">MEGAZONE</h2>
          </div>
          <div className="contents">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4 col-center"><p>20 min</p></div>
              <div className="col-4 col-center"><p>30 min</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Lundi au Jeudi</p></div>
              <div className="col-4 col-center"><p>6€</p></div>
              <div className="col-4 col-center"><p>8€</p></div>
              <hr />
            </div>
            <div className="row">
              {/* <p className="col-3"></p> */}
              <p className="weekend col-center">Week-end, veille & jours férié, vacances scolaires</p>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Client</p></div>
              <div className="col-4 col-center"><p>9€</p></div>
              <div className="col-4 col-center"><p>13€</p></div>
              <hr />
            </div>
          </div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="details">
            <p className="p-white subDetails">*SUR PRÉSENTATION DE LA CARTE ÉTUDIANTE VALIDE DE L’ANNÉE EN COURS</p>
            <p className="p-white subDetails">TOUTE PARTIE COMMENCÉE N’EST PAS REMBOURSÉ <br /> NOS OFFRES SONT NON CUMULABLES</p>
          </div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="empty_space"></div>
          <div className="title">
            <p className="title-part1">Quiz</p>
            <h1 className="title-part2">BOXING</h1>
          </div>
          <div className="contents">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4 col-center"><p>4 à 6 Joueurs</p></div>
              <div className="col-4 col-center"><p>7 à 12 Joueurs</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Heures creuses (Du dimanche 19h** Au vendredi 17h**
                Hors veilles et jours fériés)</p></div>
              <div className="col-4 col-center"><p>20€/Pers</p></div>
              <div className="col-4 col-center"><p>16€/Pers</p></div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4"><p>Heures pleines (Du vendredi 17h**Au dimanche 19h**
                Y compris veilles et jours fériés)</p></div>
              <div className="col-4 col-center"><p>25€/Pers</p></div>
              <div className="col-4 col-center"><p>21€/Pers</p></div>
              <hr />
            </div>
          </div>
          <div className="empty_space"></div>
          <div className="details">
            <p>Prix par session d’une heure et par personne</p>
            <p>**PARTIES TERMINÉES AVANT L’HEURE INDIQUÉE.</p>
          </div>

        </Body>
      </Container>
    </HelmetProvider>
  )
}

const Container = styled.div`
  margin: 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
`;

const Body = styled.div`
  padding:80px 10% 70px;
  background-color: #0a0a0a;
  >.title{
    width:100%;
    vertical-align: middle;
    text-align: center;
    padding-bottom:55px;
    >.title-part1{
      font-family: Quentin,script;
      color: #f6202c;
      font-size: 80px;
      position:relative;
      /* top: 12px; */
      font-weight: 500;
      margin:0;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
    >.title-part2{
      font-size:70px;
      font-weight:900;
      margin:0;
      font-size: 60px;
      color: white;      
      font-family: "Oswald",sans-serif;
      letter-spacing: .02em;
      text-transform: uppercase;
      @media (max-width:768px) {
        font-size: 45px;
      }
    }
  }
  >.contents{
    color: white;
    font-family: 'Oswald';
    letter-spacing: .02em;
    font-weight: 500;
    font-size: 19px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
    >hr{
      margin: 0;
      opacity: 1;
    }
    p{
      margin: 3px 6px 20px;
      line-height: normal;
    }
    >.row-red{
      background-color: #f6202c;
      /* padding-bottom: 10px; */
      padding-top:10px;
      /* margin-bottom: 8px; */
      >div >.twoline{
        margin-bottom: 12px !important;
        text-align: center;
      }
      /* @media (max-width: 670px){
        >div{
          display: table-cell;
          width: auto;
        }
      } */
    }
    >.row-yellow{
      background-color: #ffb853;
      padding-top:10px;
      
      >.col-8 {
        position: relative;
        >p{
          margin-left: 0;  
          text-align: center ;
          margin-bottom: -8px;
          padding-bottom: 10px;
        }
        >span{
          display: block;
          /* position: absolute; */
          margin-top: 0px !important;
          
          /* margin-bottom: 12px !important; */
          text-align: center;
          line-height: 1;
          padding-bottom: 10px;

          /* bottom: 12px;
          left: 23px; */
        }
      }
    }
    >.row {
      >hr{
      margin: 0;
      opacity: 1;
    }
      >div >.twoline{
      /* margin-bottom: 0px; */
      }
      >.col-sm-4{
        padding: 0;
      }
      >.col-sm-9{
        padding: 0;
      }
      >.col-4{
        padding: 0;
      }
      >.col-3{
        padding: 0;
        margin: 0;
      }
      .col-center{
        text-align: center;
      }
      >.col-sm-9 ,>.col-8{ 
        color: black;
        overflow: hidden;
        >span{
          font-size: 11px;
          font-family: Montserrat;
          font-weight: 400;
          margin-top: -10px;
          padding-right: 10px;
          @media (max-width:500px) {
            font-size: 9px;
            /* white-space: nowrap; */
            line-height: normal !important;
          }
        }
      }
      >.col-auto{
        margin-left: 0;
        padding-left: 0;
      }
      .weekend{
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
      }
      >.col-3 >p >.subtitle{
        font-size: 11px;
        font-family: Montserrat;
        position: absolute;
        /* margin-top: -8px; */
        font-weight: 400;
        @media (max-width:767px) {
          font-size: 9px;
        }
      }
    }
  }
  >.details{
    text-align: center;
    color:#f6202c;
    font-size: 18px;
    >p{
      margin-bottom: 0;
    }
    >.p-blue{
      color: #20daf6;
    }
    >.p-white{
      color: white;
    }
    >.p-white-petit{
      font-size: 14px;
    }
    >.subDetails{
      font-size: 15px;
      font-weight: 600;
      line-height: 2;
    }
  } 
`

export default NosTarifs;
